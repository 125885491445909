import { useState } from "react";
import { BrowserView, isMobile } from "react-device-detect";

export default function AboutUs(props){

    const links = ["Residential","Commercial","Yatching","Cource"];

    const [selectedTabIndex,setSelectedTabIndex] = useState(0);

    return(
        <>
            <div className="vertical-center-element">
                <div className="blue-title-text">
                À PROPOS DE NOUS
                    </div>                    
                    {!isMobile ? <br/> : ""}
                <div className="tab-block">
                    {/* <div id="tab_links">
                        {links.map(item=>
                            <div className={selectedTabIndex == links.indexOf(item) ? "selected-tab-text" : "faded-tab-text"} >
                                {item}
                            </div>)
                            }
                    </div> */}
                
                    {!isMobile ? <br/> : ""}
                    <br/>
                    <div id="content">
                    <div id="image">
                            <img src="5379637510122105177.jpg"/>
                        </div>
                        <div id="text">
                         L’agence immobilière LM Platinum Estates est une entreprise camerounaise immobilière qui a un engagement sérieux avec la satisfaction du client au premier plan. À travers une équipe d’agents expérimentés et un travail acharné, nous proposons de nombreux services à l’intention des vendeurs, des investisseurs et des locataires. Active sur le marché de l’immobilier au Cameroun, nous préconisons fortement l’innovation, l’honnêteté et la transparence. En effet, compte tenu des challenges et des défis sur le marché de l’immobilier de nos jours et surtout au Cameroun, nous visons l’excellence.
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
