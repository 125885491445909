import logo from './logo.svg';
import './App.css';
import Main from './pages/main';
import { Routes, Route } from "react-router-dom";
import Admin from './pages/admin';
import Apartments from './pages/apartments';
import Details from './pages/apart-details';
import { MobileView } from 'react-device-detect';
import SocialLinks from './components/social-links';
import Footer from './components/footer';
import ReactGA from "react-ga4";
import UnderMaintenance from './pages/undermaintenance';

function App() {
  ReactGA.initialize("G-FMYXPP1WHC");
  return (
      <>
      <Routes>
          {/* <Route path="/" element={<UnderMaintenance />}/>   */}
          <Route path="/" element={<Main />}/>      
          <Route path="/admin" element={<Admin />}/>
          <Route path="/apartments-search" element={<Apartments />}/>
          <Route path="/apart" element={<Details />}/>
      </Routes>  
      <MobileView>
          <div className="mobile-socials">
              <SocialLinks/>
          </div>
      </MobileView>
      <Footer/>
      </>
  );
}

export default App;
