import Header from "../components/header";
import LeftMenu from "../components/left-menu";
import ApartCard from "../components/apart-card";
import { useEffect, useState, useTransition } from "react";
import * as Constants from "../config";
import * as Query from "../static data/queryparams";
import {checkArrayDefined, checkArrayJustDefined} from "../tools/valueshelper";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import NothingFound from "../components/nothing-found";
import { useTranslation } from "react-i18next";

export default function Apartments(){    
    const { t } = useTranslation();
    const [apartments, setApartments] = useState();     
    const[pagesCount, setPagesCount] = useState(1);
    const[currentPage,setCurrentPage] = useState(1);
    const[resultPerPage,setResultPerPage] = useState(10);
    const [pageApartments, setPageApartments] = useState([]);
    const [filters, setFilters] = useState(getUrlParams);  
    const [allAparts, setAllAparts] = useState([]);    
    const [loaded,setLoaded] = useState(false);
    
    function getUrlParams(){
        var filt = {};
        let search = window.location.search;
        let params = new URLSearchParams(search);
        filt[Query.bedrooms] = params.get(Query.bedrooms);
        filt[Query.neighborhood] = params.get(Query.neighborhood);
        filt[Query.offertype] = params.get(Query.offertype);
        filt[Query.property] = params.get(Query.property);
        filt[Query.showers] = params.get(Query.showers);
        filt[Query.pricefrom] = params.get(Query.pricefrom);
        filt[Query.priceto] = params.get(Query.priceto);
        filt[Query.landingareafrom] = params.get(Query.landingareafrom);
        filt[Query.landingareato] = params.get(Query.landingareato);
        return filt;
    }
    function samePropertyApartsList(){
        var sameProperty = allAparts.filter(x=>checkArrayDefined(x.multiplepropertytype) && x.multiplepropertytype.indexOf(filters.property) !=-1).sort(() => Math.random() - Math.random()).slice(0, 3);
        if(sameProperty == null || sameProperty.length == 0){
            sameProperty = allAparts.filter(x=>x.displayOnMain == true);
        }
        return sameProperty.map(x=>
            <div key={x.id}>
                <ApartCard data={x}/>
            </div>
        )
     }

    function filterData(data){
        var result = data;
        if(checkArrayDefined(filters.bedrooms))
        {
            if(filters.bedrooms == "10+")
                result = result.filter(x=>Number(x.bedrooms) >= 10);
            else                
                result = result.filter(x=>x.bedrooms == filters.bedrooms);
        }
        if(checkArrayDefined(filters.showers))
        {
            if(filters.bedrooms == "10+")
                result = result.filter(x=>Number(x.showers) >= 10);
            else                
                result = result.filter(x=>x.showers == filters.showers);
        }
        if(filters.neighborhood != null && filters.neighborhood.length > 0)
            result = result.filter(x=>x.neighborhood.toLowerCase().includes(filters.neighborhood.toLowerCase()));
       if(checkArrayDefined(filters.pricefrom))
            result = result.filter(x=>Number(x.price) >= Number(filters.pricefrom));
       if(checkArrayDefined(filters.priceto))
            result = result.filter(x=>Number(x.price) <= Number(filters.priceto)); 
       if(checkArrayDefined(filters.landingareafrom))
            result = result.filter(x=>Number(x.landarea) >= SVGAnimatedNumberList(filters.landingareafrom));
       if(checkArrayDefined(filters.landingareato))
            result = result.filter(x=>Number(x.landarea)  <= Number(filters.landingareato));
        if(checkArrayDefined(filters.offertype))
            result = result.filter(x=>x.typeofoffer == filters.offertype); 
        if(checkArrayDefined(filters.property))
            result = result.filter(x=>checkArrayDefined(x.multiplepropertytype) && x.multiplepropertytype.indexOf(filters.property) !=-1);
        return result;
    }

    function getData() {
        fetch(Constants.apiurl + Constants.apartments, Constants.requestHeaderGet())
        .then((response) => response.json())
        .then((data) => {
            setAllAparts(data);
            var filtered = filterData(data);
            setPagesCount(Math.ceil(filtered.length/resultPerPage));
            setApartments(filtered);
            setLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    useEffect(() => {
        getPageContent();
     }, [apartments]);

     function getPages(){
        var i = 1;
        var arr = [];
        while(i<=pagesCount){
            arr.push(i);
            i++;
        }
        return arr.map(x=>
            <div className={currentPage==x ? "selected-page" : ""} onClick={()=>setCurrentPage(x)}>
                {x}
            </div>
        );
     }
     
     useEffect(()=>{
        getPageContent();
     },[currentPage]);

     function goToAnchor(anchor){
        const posy = document.getElementById(anchor)?.getBoundingClientRect().top;
        document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });   
        const yOffset = -160; 
        const y = posy + window.scrollY + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

     function getPageContent(){
        if(checkArrayJustDefined(apartments))
        {
            var start = (currentPage - 1)*resultPerPage;
            var end = start + resultPerPage;
            var show = apartments.slice(start,end);
            setPageApartments(show);
            goToAnchor("apart-result");        
        }
     }
     

     function setNext(){
        if(currentPage == pagesCount)
            return;
        setCurrentPage(currentPage+1);
     }
     function setPrevious(){
        if(currentPage == 1)
            return;
        setCurrentPage(currentPage-1);
     }

     function apartsList(aparts){

        return aparts.map(x=>
            <div key={x.id}>
                <ApartCard data={x}/>
            </div>
        )
     }

    return (
        <>
            <Header/>        
            <div id="page">
                <div className="left-menu">
                    <LeftMenu/>
                </div>
                <div className="main-page-content">    
                
                    {loaded? 
                    <>
                        <div id="apart-result">
                            <div >
                                <div id="aparts-cards">
                                    {!!(pageApartments) && pageApartments.length > 0 ? apartsList(pageApartments) : <NothingFound/>}
                                </div>
                            </div>
                            {pagesCount > 1 ?
                                <div id="pagination">
                                        <div onClick={setPrevious}>
                                            &#60;                                
                                        </div>
                                        {
                                        getPages()  
                                        } 
                                        <div onClick={setNext}>
                                            &#62;                               
                                        </div>    
                                </div>
                                :
                                ""
                            }
                        </div>
                        
                        <div id="check-other">
                                    {t('Consultez d’autres propositions')}
                                </div>
                                <div id="aparts-cards">                                
                                    
                                    {samePropertyApartsList() }
                        </div>
                    </>
                    :
                    <div className="loader-box">
                        <div className="loader"></div> 
                    </div>
                    }  
                 </div>
            </div>
        </>
    )
}