import { useEffect, useRef, useState } from "react";
import Input from "./input";
import Select from "./select";
import AddOptions from "./add-options";
import * as Constants from "../../config";
import axios from "axios";
import * as Vaules from "../../static data/selectvalues";
import DateTimeInput from "./datetimeinput";
import { checkArrayDefined } from "../../tools/valueshelper";

export default function AddApartment(props){
    const [imagesState,setImagesState] = useState();
    const [dataInProgressState,setDataInProgressState] = useState(false);
    const inputFile = useRef(null);    
    const [selectedPropertyType, setSelectedPropertyType] = useState(Vaules.propertyValues[0]);

    const [apartment, setApartment] = useState(props.apart);

    function inputChanged(e){
        const { name, value } = e.target;
        var newObj = {};
        Object.assign(newObj,apartment);
        newObj[name] = value;
        setApartment(newObj);
    }    
    function propertyTypeChanged(e){
        const { name, value } = e.target;
        setSelectedPropertyType(value);
    }  
    function addproperty(){
        var newObj = {};
        Object.assign(newObj,apartment);
        if(!checkArrayDefined(newObj.multiplepropertytype))
            newObj.multiplepropertytype = [];
        if(newObj.multiplepropertytype.indexOf(selectedPropertyType) != -1)
            return;
        newObj.multiplepropertytype.push(selectedPropertyType);
        setApartment(newObj);
    }

    function deletePropertyType(i){
        var newObj = {};
        Object.assign(newObj,apartment);
        newObj.multiplepropertytype.splice(i,1);
        setApartment(newObj);
    }

    function updateOptions(properties){
        var newObj = {};
        Object.assign(newObj,apartment);
        newObj.properties = properties;
        setApartment(newObj);
    }

    function updateImages(image, del = false){
        var newObj = {};
        Object.assign(newObj,apartment);
        if(typeof(newObj.images) == "undefined" && newObj.images == null)
            newObj.images = [];
        const i = newObj.images.findIndex(x=> x.link == image.link);
        if(del){
            newObj.images.splice(i,1);
            setApartment(newObj);
            return;            
        }
        if(i<0)
            newObj.images.push(image);
        else
            newObj.images[i] = image;
        setApartment(newObj);
    }

    function updateImagesRange(images){
        var newObj = {};
        Object.assign(newObj,apartment);
        if(typeof(newObj.images) == "undefined" && newObj.images == null)
            newObj.images = [];
        newObj.images.push(...images);
        setApartment(newObj);
    }

    function saveApart(){
        if(!checkArrayDefined(apartment.multiplepropertytype)){
            alert("Add at least one Type de bien immobilier");
            return;
        }
        props.saveApart(apartment);
    }
    
    function closeWindow(){
        props.closeWindow();
    }

    useEffect(()=>{
        updateImagesAfterUpload();
    },[imagesState])

    function getImages() {
        fetch(Constants.apiurl + Constants.imagesListPath + "?id=" + apartment.id)
        .then((response) => response.json())
        .then((data) => {
            setImagesState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    function updateImagesAfterUpload(){
        var newImages = [];
        console.log(imagesState);
        for(var i in imagesState){
            if(typeof(apartment.images) == "undefined" && apartment.images == null)
            {
                imagesState[i].order=99;
                newImages.push(imagesState[i]);
                continue;
            }     
            var ind = apartment.images.findIndex(x=>x.name == imagesState[i].name);
            if(ind<0){
                imagesState[i].order=99;
                newImages.push(imagesState[i]);
            }
        }
        updateImagesRange(newImages);
    }

    function dropHandler(ev) {      
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        var files = [];
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          [...ev.dataTransfer.items].forEach((item, i) => {
            // If dropped items aren't files, reject them
            if (item.kind === "file") {              
              const file = item.getAsFile();
              files.push(file);
            }
          });
        } else {
          // Use DataTransfer interface to access the file(s)
          [...ev.dataTransfer.files].forEach((file, i) => {
            files.push(file);
          });
        }
        uploadImages(files);
      };     

      const handleFileUpload = (event) => {
        const files = event.target.files;
        if(!(files[0]))return;
        uploadImages(files);
      };

      function uploadImages(files){
        var update = false;
        if(files.length < 1) return;        
        for(var i in files){
            var file = files[i];
            if(!(file.name) || file.name=="")return;
            update=true;
            //file.name = apartment.id + "/" + file.name;
            // imagesState.forEach(item=>{
            //     if(item.name===file.name)
            //         {
            //             alert("file already exist");
            //             return; 
            //         }
            // });
            const formData = new FormData();         
            setDataInProgressState(true);
            formData.append("file", file);
            axios
                .post(Constants.apiurl + Constants.imageUpload + "?id=" + apartment.id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem("token")
                },
                })
                .then(response => {
                    if(response.status == 401) window.location.reload(false);
                    getImages();
                    return response.json();
                })
                .catch((error) => { 
                    setDataInProgressState(false);
                });
        }         
        if(update)getImages();    
        setDataInProgressState(false);
      }

      function changeOrder(e){
        const {id, name, value } = e.target;
        var newObj = {};
        Object.assign(newObj,apartment);
        const i = newObj.images.findIndex(x=> x.name == id);
        newObj.images[i].order=value;
        newObj.images.sort((a, b) => a.order -b.order);
        setApartment(newObj);
      }

      function dragOverHandler(ev) {
      
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
      };

      const deleteImage = (x) => {
          setDataInProgressState(true);
          fetch(Constants.apiurl + Constants.deleteimagePath + "?name=" + x.name)
          .then((data) => {
              updateImages(x,true);
              setDataInProgressState(false);
          })
          .catch((err) => {            
              setDataInProgressState(false);
              console.log(err.message);
          });
      };

    return(
        <>
            <div id="add_apartment_block">
                <Input value={apartment.name} name="name" inputChanged={inputChanged}  label="Nom" />
                <Select value={apartment.status} name="status" inputChanged={inputChanged}  label="Status" values={Vaules.statusValues} />
                {apartment.status == Vaules.Reservedtatus ? 
                <>
                    <DateTimeInput value={apartment.reservedfrom} name="reservedfrom" inputChanged={inputChanged}  label="From" />
                    <DateTimeInput value={apartment.reservedto} name="reservedto" inputChanged={inputChanged}  label="To" />
                </>
                :
                ""}
                {apartment.status == Vaules.Promotiontatus ? 
                <>
                    <Input value={apartment.promotionprice} name="promotionprice" inputChanged={inputChanged}  label="Promotion Prix" />
                </>
                :
                ""}
                <Input value={apartment.price} name="price" inputChanged={inputChanged}  label="Prix" />
                <Input value={apartment.landarea} name="landarea" inputChanged={inputChanged}  label="Superficie" />
                <Input value={apartment.livingarea} name="livingarea" inputChanged={inputChanged}  label="Superficie habitable" />
                    <div className="inline-blocks">
                        <Select value={selectedPropertyType} name="propertytype" inputChanged={propertyTypeChanged} values={Vaules.propertyValues}  label="Type de bien immobilier" />
                        
                        <div className="add-button" onClick={addproperty}>Add Property</div>
                    </div>
                    <div className="inline-blocks">
                        <div>Current Properties:       </div>         
                        <div className="options-blocks">
                            {checkArrayDefined(apartment.multiplepropertytype) ? apartment.multiplepropertytype.map((x,i)=>
                                                    <div key={x + Math.random()}>
                                                    {x}
                                                    <label onClick={()=>deletePropertyType(i)}>X</label>
                                                </div>
                            ):""}
                        </div>
                    </div>

                <Select value={apartment.typeofoffer} name="typeofoffer" inputChanged={inputChanged} values={Vaules.offerValues}  label="Type d'offre" />
                <Input value={apartment.neighborhood} name="neighborhood" inputChanged={inputChanged} label="Emplacement" />
                <Select value={apartment.bedrooms} name="bedrooms" inputChanged={inputChanged} values={Vaules.chambersValues}  label="chambres" />
                <Select value={apartment.showers} name="showers" inputChanged={inputChanged} values={Vaules.bathValues}  label="Douches" />
                <AddOptions updateOptions={updateOptions} properties={apartment.properties}/>
            </div>
            <div
                onClick={()=>inputFile.current.click()}
                id="drop_zone"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}>
                <p>Faites glisser un ou plusieurs fichiers vers ce <i>Mettre votre fichier ici</i>.</p>
            </div>         
            {dataInProgressState ? <div className="loader"></div> : ""}   
            <div id="apart-images">
                {!!(apartment.images) ? apartment.images.map(x=>
                    <div>
                        <div onClick={()=>deleteImage(x)} className="close-icon">X</div>
                        <img  src={x.link}/>
                        <input value={x.order} id={x.name} onChange={changeOrder} />
                    </div>
                ) : ""}
            </div>
            <div className="buttons-block">
                <div onClick={saveApart} className="add-button">Save</div>
                <div onClick={closeWindow} className="delete-button">Fermer</div>
            </div>
            <input onChange={handleFileUpload} accept="image/png, image/gif, image/jpeg" ref={inputFile} type="file" id="pictures" style={{display:"none"}} name="files" multiple/>
        </>
    )
}
